import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import SummaryContainer from '../../components/section-summary/summary-container'

const Summary03 = (props) => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  return (
    <>    
      
      {posts &&
            posts.map(({ node: post }) => (
                <>
                    <SummaryContainer 
                      id={post.frontmatter.sectionCode} 
                      title={post.frontmatter.title}
                      text={post.frontmatter.summaryText}
                      linksTo={post.frontmatter.linksTo}
                    />
                </>
        ))}

    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query Summary03Query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "summary-03" } } }
        ) {
        edges {
            node {
                id
                frontmatter {
                    title
                    templateKey
                    sectionCode
                    summaryText
                    linksTo                    
                }
            }
        }
      }
      }
    `}
    render={(data) => <Summary03 data={data} />}
  />
)
