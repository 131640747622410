import React from "react"
import { withPrefix, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Hero01 from "../templates/home/hero-01-template"
import Hero02 from "../templates/home/hero-02-template"
import Hero03 from "../templates/home/hero-03-template"
import Hero04 from "../templates/home/hero-04-template"
import Hero05 from "../templates/home/hero-05-template"

import Summary01 from "../templates/home/summary-01-template"
import Summary02 from "../templates/home/summary-02-template"
import Summary03 from "../templates/home/summary-03-template"
import Summary04 from "../templates/home/summary-04-template"
import Summary05 from "../templates/home/summary-05-template"

import ContactSection from "../templates/contact-us/contact-us-details-template"


const IndexPage = () => (
  <Layout>

    <SEO title="Home" />

    <Hero01 />

    {/* About Us */}
    <Summary01 />

    <Hero02 />

    {/* The Team */}
    <Summary02 />

    <Hero03 />

    {/* Tailored Solutions */}
    <Summary03 />

    <Hero04 />

    {/* Why EveryOne Homes */}
    <Summary04 />

    <Hero05 />

    {/* Projects */}
    <Summary05 />

    <section className="contact-section-wrapper">
      <div className="contact-section-container">
        <ContactSection />
      </div>
    </section>

  </Layout>
)

export default IndexPage
