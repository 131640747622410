import React from 'react'
import PropTypes from 'prop-types'
// import Content, { HTMLContent } from '../components/Content'
import RemarkFormatter from '../remark-formatter'
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import { Link } from 'gatsby'
import LinkArrow from '../../images/graphic-elements/link-arrow-blue.svg'


const SummaryContainer = ({ id, title, text, linksTo }) => {

     return (

        <section
                id={id}
                className="summary-section-wrapper"
            >
                <div className="summary-section-container">

                    {/* <div className="content-container section-title">
                        <h1>{title}</h1>
                    </div> */}

                    <div className="content-container section-content">
                        
                        {(title || text) &&
                            // Column 1
                            <div className="section-wrapper flex-column">
                                {title &&
                                    <h1 className="section-heading">{title}</h1>
                                }

                                {text &&
                                    <RemarkFormatter className="section-text-remarkable" content={text} />
                                }      
                            </div>
                        }

                        {linksTo &&
                            // Column 2
                            <div className="section-wrapper flex-column page-link-wrapper">
                                <div className="section-link">
                                    <Link className="page-link" to={linksTo}><span className="link-label">Learn More</span><img className="link-icon" src={LinkArrow} alt="" /></Link>                  
                                </div>
                            </div>
                        }           

                    </div>
               </div>
            </section>     
    )
}

export default SummaryContainer