import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import HeroContainer from '../../components/section-hero/hero-container'

const Hero01 = (props) => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  return (
    <>    
      
      {posts &&
            posts.map(({ node: post }) => (
                <>
                    <HeroContainer 
                      id={post.frontmatter.sectionCode} 
                      overlay={post.frontmatter.overlay}
                      imgDesktop={post.frontmatter.imageDesktop}
                      imgMobile={post.frontmatter.imageMobile}
                      caption={post.frontmatter.caption}
                      linksTo={post.frontmatter.linksTo}
                    />
                </>
        ))}

    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query Hero01Query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "hero-01" } } }
        ) {
        edges {
            node {
                id
                frontmatter {
                  title
                  templateKey
                  sectionCode
                  linksTo
                  overlay
                  imageDesktop
                  imageMobile
                  caption
                }
            }
        }
      }
      }
    `}
    render={(data) => <Hero01 data={data} />}
  />
)
